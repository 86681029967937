<template>
    <div class="oreder-bet-list">
        <h3>Заказы ставок. <!-- Найдено <span>{{totalElements}}</span> --></h3>
        <div>
            <div style="width: 150px; display: inline-block">
                <b-form-select v-model="selectedBookmaker"
                           :options="bookmakers"
                           aria-placeholder="sss"
                           class="mb-2"></b-form-select>
            </div>
            |
            <div style="width: 150px; display: inline-block">
                <b-form-select v-model="selectedStatus"
                               :options="statuses"
                               aria-placeholder="sss"
                               class="mb-2"></b-form-select>
            </div>
            |
            <div style="width: 350px; display: inline-block">
                <b-form-datepicker id="filter-play-at"
                                   placeholder="Дата матча"
                                   v-model="filterPlayAt"
                                   reset-button
                                   class="mb-2"></b-form-datepicker>
            </div>
            |
            <span>
                <button class="btn btn-success btn-sm" v-on:click="loadOrders">
                    <b-icon icon="arrow-repeat"></b-icon>
                </button>
            </span>

        </div>
        <table id="order-bet-list-table" class="table order-bet-list-table">
            <tr>
              <th>Bet ID</th>
              <th>Book</th>
              <th>Tournament</th>
              <th>Play At</th>
              <th>Event</th>
              <th>Bet Type</th>
              <th>Count</th>

            </tr>
            <tr v-for="ob in orders" v-bind:key="ob.id">
              <td width="150px">
                  <router-link :to="{ name: 'OrderBetsListGroupBet', params: { parentOrderId: ob.parentOrderId }}">{{ ob.betId }}</router-link>
                <br>
                <a v-if="null != ob.blog"
                   :href="getBlogUrl(ob.blog.id)"
                   target="_blank">{{ob.blog.title}}</a>
              </td>
              <td>
                {{ getBookmakerName(ob.event.bookmakerId) }}
              </td>
              <td>
                  {{getSportName(ob.event.sport)}}.
                  {{ob.event.tournamentName}}<br/>
                  {{ ob.event.eventId }}
              </td>
              <td>
                {{ new Date(ob.event.playAt) | moment("YYYY-MM-DD HH:mm") }}
              </td>
              <td>
                {{ ob.event.home }} - {{ ob.event.away }}
              </td>
              <td width="150px">{{ ob.betName }}<br>{{ob.period}}</td>
              <td>{{ ob.groupCount }}</td>
            </tr>
        </table>
        <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                v-on:change="selectPage"
                aria-controls="users-list"
        ></b-pagination>

    </div>
</template>

<script>
    import OrderBetService from "../services/OrderBetService";
    import {BookmakerType, SportType} from "../services/enums"

    export default {
        name: "OrderBetsListGroup",
        data: function(){
            return {
                totalElements: 0,
                currentPage: 1,
                totalRows: 0,
                perPage:1,
                orders: [],
                statuses: [
                    {value: null, text:'Статус'},
                    {value: 'OPEN', text:'Open'},
                    {value: 'PLAY', text:'Play'},
                    {value: 'WON', text:'Won'},
                    {value: 'HALF_WON', text:'Half won'},
                    {value: 'LOSS', text:'Loss'},
                    {value: 'HALF_LOSS', text:'Half loss'},
                    {value: 'REFUND', text:'Refund'},
                    {value: 'CANCEL', text:'Cancel'},
                    {value: 'FAILED', text:'Failed'},
                ],
                selectedStatus: null,
                filterCreatedAt: '',
                filterPlayAt: '',

                bookmakers: [
                  {value: null, text:'Букмекер'},
                  {value: 1, text: 'Pinnacle'},
                  {value: 3, text: 'Mollybet'},
                  {value: 4, text: 'Vodds'},
                ],
                selectedBookmaker: null
            }
        },
        methods: {
            selectPage(page) {
                this.currentPage=page;
                this.loadOrders()
            },
            loadOrders(){
                var self = this;
                var filter = {};
                if (this.selectedStatus != null) {
                    filter["status"] = this.selectedStatus;
                }
                if (this.filterPlayAt != '') {
                    filter["playAt"] = this.filterPlayAt;
                }
                if (this.filterCreatedAt != '') {
                    filter["createdAt"] = this.filterCreatedAt;
                }

                if (this.selectedBookmaker != null) {
                    filter["bookmakerId"] = this.selectedBookmaker;
                }

                OrderBetService.getOrderBetsListGroup(self.currentPage-1, filter)
                    .then((response) => {
                        self.orders = response.data.content;

                        self.totalElements = response.data.totalElements;
                        self.currentPage = response.data.number+1;
                        self.totalRows = response.data.totalElements;
                        self.perPage = response.data.size;
                    })
            },
            getBookmakerName(id) {
                return BookmakerType[id];
            },
            getSportName(id) {
                return SportType[id];
            },
            getBlogUrl(id) {
                return 'https://expari.com/blog/' + id
            }
        },
        watch: {
            selectedStatus: function() {
                this.loadOrders();
            },
            filterPlayAt: function() {
                this.loadOrders();
            },
            filterCreatedAt: function() {
                this.loadOrders();
            },
            selectedBookmaker: function() {
              this.loadOrders();
            }
        },
        mounted() {
            this.loadOrders()
        }
    }
</script>
